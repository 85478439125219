import ServiceCard from "../components/ServiceCard";
import { services } from "../utils/services";

const Service = () => {
  return (
    <div className="lg:w-[85vw] w-[90vw] flex flex-col m-auto mt-4 gap-10">
      <div className="flex flex-col w-full lg:w-[85vw] gap-6 justify-center items-center">
        <div className="font-display text-heading text-3xl lg:text-5xl font-bold text-center">
          Our Professional Services
        </div>
        <div className="font-display text-para text-base lg:text-lg text-center">
          We offer a comprehensive range of professional services designed to meet diverse needs efficiently and effectively. Our offerings ensure the highest level of satisfaction. Trust us to deliver exceptional quality and personalized solutions for your requirements.
        </div>
      </div>
      <div className="flex flex-col justify-center items-center gap-10">
        {services.map((service, index) => (
          <ServiceCard key={index} data={service} num={index} display={service.display} setDisplay={service.setDisplay} />
        ))}
      </div>
    </div>
  );
};

export default Service;

import React, { useState } from "react";
import ReviewCard from "../components/ReviewCard";

const ReviewCarousel = ({ reviews }) => {
  const scrollCarousel = (direction) => {
    const carousel = document.querySelector(".carousel");
    const scrollAmount = 300; // Adjust this value as needed

    if (direction === "left") {
      carousel.scrollLeft -= scrollAmount;
    } else if (direction === "right") {
      carousel.scrollLeft += scrollAmount;
    }
  };

  return (
    <div className="relative w-[85vw] overflow-hidden flex justify-center items-center flex-col">
      <div className="flex gap-6 overflow-x-scroll pb-4 scroll-smooth carousel">
        {reviews.map((review, index) => (
          <ReviewCard key={index} card={review} />
        ))}
      </div>
      {reviews.length > 0 ? (
        <div className="flex justify-between mt-4 gap-6">
          <button
            className="bg-primary1 text-white rounded-full w-10 h-10 flex items-center justify-center shadow-lg"
            onClick={() => scrollCarousel("left")}
          >
            &#8249;
          </button>
          <button
            className="bg-primary1 text-white rounded-full w-10 h-10 flex items-center justify-center shadow-lg"
            onClick={() => scrollCarousel("right")}
          >
            &#8250;
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ReviewCarousel;

import { useEffect, useState } from "react";
import { getReviews, createReview } from "../api/review";
import { useSnackbar } from "notistack";
import testimonials from "../images/testimonials.png";
import ReviewCard from "../components/ReviewCard";
import ReviewCarousel from "../components/ReviewCarousel";

const Review = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    designation: "",
    message: "",
    rating: "",
    image: null,
  });

  useEffect(() => {
    getReviews()
      .then((res) => {
        setReviews(res.reviews);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.message, { variant: "error" });
      });
  }, [enqueueSnackbar]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: name === "image" ? files[0] : value,
    });
    console.log(formData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.name);
    formDataToSend.append("designation", formData.designation);
    formDataToSend.append("message", formData.message);
    formDataToSend.append("rating", formData.rating);
    formDataToSend.append("image", formData.image);

    createReview(formDataToSend)
      .then((response) => {
        if (response.status === "Success") {
          setFormData({
            name: "",
            designation: "",
            message: "",
            rating: "",
            image: null,
          });
        }
        enqueueSnackbar(response.message, { variant: "success" });
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.message, { variant: "error" });
      });

    setPopupOpen(false);
  };

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  return (
    <div className="w-[90vw] lg:w-[85vw] min-h-[89vh] m-auto flex flex-col items-center mt-4 gap-10">
      <div className="flex flex-col md:flex-row justify-center items-center gap-2">
        <div className="flex flex-col w-[85vw] md:w-[51vw] gap-6 md:items-start items-center">
          <div className="font-display text-heading text-[40px] font-semibold">
            We Value Your Feedback
          </div>
          <div className="font-display text-para text-center lg:text-left text-lg">
            Client feedback is integral to our process. We embrace constructive
            insights to continuously improve our services, ensuring that every
            project not only meets but exceeds expectations. Your feedback
            drives our commitment to excellence and client satisfaction.
          </div>
          <button
            className="font-display text-primary1 underline text-xl font-semibold cursor-pointer mt-4"
            onClick={togglePopup}
          >
            Share your review
          </button>
        </div>
        <div className="flex justify-center items-center w-[85vw] md:w-[34vw]">
          <img
            src={testimonials}
            alt="review"
            className="max-h-[350px] max-w-[420]"
          />
        </div>
      </div>
      <div className="flex flex-col w-[85vw] gap-6 justify-center items-center">
        <div className="font-display text-heading text-[50px] font-bold text-center">
          Hear what they say about us
        </div>
        <div className="font-display text-para text-lg text-center">
          "Reliable, innovative, and always client-focused."
        </div>
      </div>
      <ReviewCarousel reviews={reviews} />
      {isPopupOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg shadow-lg w-[80%] md:w-[60%] lg:w-[50%]">
            <h2 className="text-3xl font-bold text-center mb-4">
              Share Your Review
            </h2>
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div className="flex flex-col">
                <label
                  htmlFor="name"
                  className="text-heading font-semibold font-display mb-1"
                >
                  Your Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Enter your name"
                  className="w-full px-4 py-2 rounded-md font-display border text-para border-heading focus:outline-none focus:border-primary1"
                  required
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="designation"
                  className="text-heading font-semibold font-display mb-1"
                >
                  Designation
                </label>
                <input
                  type="text"
                  id="designation"
                  name="designation"
                  value={formData.designation}
                  onChange={handleInputChange}
                  placeholder="Enter your designation"
                  className="w-full font-display px-4 py-2 rounded-md border text-para border-heading focus:outline-none focus:border-primary1"
                  required
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="message"
                  className="text-heading font-semibold font-display mb-1"
                >
                  Your Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  rows="4"
                  placeholder="Enter your message"
                  className="w-full font-display px-4 py-2 rounded-md border text-para border-heading focus:outline-none focus:border-primary1"
                  required
                  minLength={50}
                ></textarea>
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="rating"
                  className="text-heading font-semibold font-display mb-1"
                >
                  Rating (1-5)
                </label>
                <input
                  type="number"
                  id="rating"
                  name="rating"
                  value={formData.rating}
                  onChange={handleInputChange}
                  placeholder="Enter your rating (1-5)"
                  className="w-full font-display px-4 py-2 rounded-md border text-para border-heading focus:outline-none focus:border-primary1"
                  required
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="image"
                  className="text-heading font-display font-semibold mb-1"
                >
                  Upload Image
                </label>
                <input
                  type="file"
                  id="image"
                  name="image"
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 rounded-md border text-para border-heading focus:outline-none focus:border-primary1"
                />
              </div>
              <div className="flex justify-center gap-2">
                <button
                  type="submit"
                  className="bg-primary1 font-display text-white px-6 py-2 rounded-md hover:bg-primary2 hover:text-primary1 hover:underline hover:border-primary1 hover:border-2"
                >
                  Submit Review
                </button>
                <button
                  type="button"
                  className="bg-primary1 font-display text-white px-6 py-2 rounded-md hover:bg-primary2 hover:text-primary1 hover:underline hover:border-primary1 hover:border-2"
                  onClick={togglePopup}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};



export default Review;

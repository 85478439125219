import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
// import logo from "../images/logo.png";
import logo from "../images/logo_orange.svg"
import sideBarIcon from "../images/sidebar.svg";
import close from "../images/close.png";

const Navbar = () => {
  const location = useLocation();
  const path = location.pathname;
  const pathSegments = path.split("/");
  const currentPath = pathSegments[pathSegments.length - 1];
  const [selectedItem, setSelectedItem] = useState();
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const navigate = useNavigate();

  const handleNavigationAndScroll = () => {
    setDropdownVisible(false);
    navigate("/");
    setTimeout(() => {
      window.scrollBy({
        top: window.innerHeight,
        left: 0,
        behavior: "smooth",
      });
    }, 100);
  };

  const items = ["Home", "About Us", "Services", "Testimonials"];
  const paths = ["", "about", "services", "reviews"];

  useEffect(() => {
    const index = paths.indexOf(currentPath);
    if (index !== -1) {
      setSelectedItem(index);
    }
  }, [currentPath]);

  const handleSidebarClick = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const handleLinkClick = (index) => {
    setSelectedItem(index);
    setDropdownVisible(false);
  };

  return (
    <div className="relative w-screen h-[11vh] grid items-center grid-cols-4 md:grid-cols-10 shadow-lg">
      <div className="col-span-2 flex justify-start md:justify-center items-center">
        <Link to="/">
          <img
            src={logo}
            alt="ClientDriven-Solutions"
            className="w-[100xp] h-[50px] md:w-[120px] md:h-[60px] object-contain ml-4 md:ml-0"
          />
        </Link>
      </div>
      <div className="w-full h-full col-span-6 hidden md:flex flex-row gap-5 justify-center items-center">
        {items.map((item, index) => (
          <Link
            key={index}
            to={`/${paths[index]}`}
            className={`text-navbar text-lg font-semibold font-display ${
              selectedItem === index ? "underline" : ""
            }`}
            onClick={() => handleLinkClick(index)}
          >
            {item}
          </Link>
        ))}
      </div>
      <div className="col-span-2 hidden md:flex justify-center">
        <p
          className="text-white bg-primary1 p-2 text-xl font-semibold font-display cursor-pointer"
          onClick={handleNavigationAndScroll}
        >
          Start With Us
        </p>
      </div>
      <div className="col-span-2 md:hidden flex justify-end mr-2 cursor-pointer">
        <img
          src={sideBarIcon}
          alt="Sidebar Icon"
          className="w-8 h-8"
          onClick={handleSidebarClick}
        />
      </div>
      <div
        className={`fixed top-0 left-0 w-full h-screen bg-black bg-opacity-75 flex flex-col transform transition-transform duration-300 ${
          isDropdownVisible ? "translate-x-0" : "translate-x-full"
        }`}
        style={{ zIndex: 1000 }}
      >
        <div className="flex justify-end mr-2 mt-10 h-[10%]">
          <img
            src={close}
            alt="close"
            className="w-8 h-8 cursor-pointer"
            onClick={handleSidebarClick}
          />
        </div>
        <div className="flex flex-col justify-center items-center h-[90%] pb-28">
          {items.map((item, index) => (
            <Link
              key={index}
              to={`/${paths[index]}`}
              className={`text-white text-3xl font-semibold my-2 ${
                selectedItem === index ? "underline" : ""
              }`}
              onClick={() => handleLinkClick(index)}
            >
              {item}
            </Link>
          ))}
          <p
            className="text-white text-2xl bg-primary1 p-2 mt-4 font-semibold cursor-pointer"
            onClick={handleNavigationAndScroll}
          >
            Start With Us
          </p>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

import React from "react";
import "../style/custom-loader.css";

const CustomLoader = () => {
  return (
    <div className="custom-loader-container">
      <div className="custom-loader">
        <div className="custom-loader-dot"></div>
        <div className="custom-loader-dot"></div>
        <div className="custom-loader-dot"></div>
      </div>
    </div>
  );
};

export default CustomLoader;

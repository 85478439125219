import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="bg-navbar text-primary2 py-8 px-4 mt-10">
      <div className="container mx-auto text-center md:text-left flex flex-col md:flex-row justify-between items-center gap-8">
        <div className="flex flex-col md:flex-row items-center gap-4 md:gap-8">
          <h1 className="text-2xl md:text-3xl font-display font-semibold mb-4 md:mb-0">
            Have an awesome project in mind?
          </h1>
          <a
            href="mailto:clientdrivensolutions@gmail.com"
            className="bg-primary1 text-primary2 font-semibold px-2 py-2 rounded-md text-lg flex items-center"
          >
            <span>Contact Us</span>
            <FontAwesomeIcon icon={faArrowRight} size="sm" className="ml-4" />
          </a>
        </div>
        <div className="flex justify-center md:justify-end gap-6 mt-8 md:mt-0">
          <a href="mailto:clientdrivensolutions@gmail.com">
            <FontAwesomeIcon icon={faEnvelope} size="2x" />
          </a>
          <a href="https://www.linkedin.com/company/clientdriven-solution/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} size="2x" />
          </a>
        </div>
      </div>
      <hr className="my-4" />
      <p className="text-primary2 text-center">© {new Date().getFullYear()} All rights reserved</p>
    </footer>
  );
};

export default Footer;

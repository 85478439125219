import UseScrollToTop from "./components/UseScrollToTop";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./screens/Home";
import Review from "./screens/Review";
import Service from "./screens/Service";
import About from "./screens/About";
import { SnackbarProvider } from "notistack";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <SnackbarProvider>
      <Router>
        <UseScrollToTop />
        <div>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/reviews" element={<Review />} />
            <Route path="/services" element={<Service />} />
            <Route path="/about" element={<About />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </SnackbarProvider>
  );
}

export default App;

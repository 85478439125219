import coreValue1 from "../images/core_values/core_value_1.jpg";
import coreValue2 from "../images/core_values/core_value_2.jpg";
import coreValue3 from "../images/core_values/core_value_3.jpg";
import coreValue4 from "../images/core_values/core_value_4.jpg";
import coreValue5 from "../images/core_values/core_value_5.jpg";
import coreValue6 from "../images/core_values/core_value_6.jpg";

export const aboutUsData = [
  {
    title: "1. Needs Assessment",
    message:
      "We begin each client engagement with a thorough needs assessment. Understanding your unique challenges, goals, and market dynamics allows us to tailor solutions that are not only effective but also aligned with your strategic objectives. .",
  },
  {
    title: "2. Collaborative Planning",
    message:
      "Collaboration is at the heart of our approach. We work closely with your team to co-create a roadmap that outlines clear deliverables, timelines, and milestones. This ensures transparency and keeps all stakeholders informed and engaged throughout the project lifecycle.",
  },
  {
    title: "3. Agile Methodology",
    message:
      "Embracing agile principles, we prioritize flexibility and adaptability in our project management approach. This allows us to respond swiftly to changing requirements, optimize resources, and deliver incremental value with each iteration.",
  },
  {
    title: "4. Continuous Communication",
    message:
      "Open and frequent communication is key to our client relationships. We provide regular updates, progress reports, and feedback sessions to ensure that we are always aligned with your expectations and evolving business needs.",
  },
  {
    title: "5. Quality Assurance",
    message:
      "Quality is non-negotiable. We adhere to rigorous quality assurance processes throughout every project phase, conducting thorough testing and validation to guarantee the delivery of robust, reliable solutions that meet industry standards and exceed your expectations.",
  },
  {
    title: "6. Scalable Solutions",
    message:
      "Whether you're a startup scaling for growth or an established enterprise seeking optimization, our solutions are designed to scale alongside your business. We anticipate future needs and architect scalable frameworks that support your long-term success.",
  },
  {
    title: "7. Post-Implementation Support",
    message:
      "Our commitment doesn’t end with project delivery. We provide comprehensive post-implementation support, including training, maintenance, and ongoing optimization services to ensure sustained performance and user satisfaction.",
  },
  {
    title: "8. Measurable Outcomes",
    message:
      "We are results-driven. From the outset, we define clear metrics and KPIs (Key Performance Indicators) to gauge project success. By focusing on measurable outcomes, we demonstrate the tangible impact of our solutions on your business objectives.",
  },
  {
    title: "9. Client Education and Empowerment",
    message:
      "Beyond implementation, we empower your team with the knowledge and tools to leverage our solutions effectively. Through targeted training sessions and documentation, we foster internal expertise and promote self-sufficiency in managing and optimizing our solutions.",
  },
];

export const coreValuesData = [
  {
    id: 1,
    title: "Integrity",
    description:
      "Integrity is the cornerstone of our business. We uphold the highest ethical standards in all our interactions, ensuring transparency, honesty, and trustworthiness with our clients, partners, and team members.",
    image:  coreValue1 ,
  },
  {
    id: 2,
    title: "Excellence",
    description:
      "We strive for excellence in everything we do, aiming to exceed expectations and deliver outstanding results.",
    image:  coreValue2 ,
  },
  {
    id: 3,
    title: "Innovation",
    description:
      "Innovation drives our progress. We embrace new ideas and technologies to stay at the forefront of our industry.",
    image:  coreValue3 ,
  },
  {
    id: 4,
    title: "Teamwork",
    description:
      "Teamwork is at the heart of our success. We collaborate effectively to achieve common goals.",
    image:  coreValue4 ,
  },
  {
    id: 5,
    title: "Customer Focus",
    description:
      "Our customers are our priority. We are committed to understanding and meeting their needs.",
    image:  coreValue5 ,
  },
  {
    id: 6,
    title: "Accountability",
    description:
      "We take responsibility for our actions and outcomes, ensuring accountability at every level.",
    image:  coreValue6 ,
  },
];

import React, { useState, useEffect } from "react";
import previous from "../images/previous.svg";
import next from "../images/next.svg";
import CustomLoader from "./CustomLoader";
import { coreValuesData } from "../utils/aboutUs";

const preloadImages = (images) => {
  images.forEach((src) => {
    const img = new Image();
    img.src = src;
  });
};

const CoreValues = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    preloadImages(coreValuesData.map((item) => item.image));
  }, []);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % coreValuesData.length);
    setLoading(true);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + coreValuesData.length) % coreValuesData.length
    );
    setLoading(true);
  };

  const { id, title, description, image } = coreValuesData[currentIndex];

  return (
    <div className="w-[90vw] lg:w-[85vw]">
      <div
        className="h-[480px] w-full flex justify-start"
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        {loading && <CustomLoader />}
        <img
          src={image}
          alt=""
          onLoad={() => setLoading(false)}
          className="hidden"
        />
        <div className="w-full flex justify-end text-[#5A5A5A] font-display font-semibold text-2xl pr-10 pt-6">
          {id}/6
        </div>
      </div>
      <div className="flex flex-col md:flex-row mt-[-150px]">
        <div className="w-full md:w-[80%] flex flex-col gap-4 justify-start">
          <p className="max-w-[600px] min-h-[70px] bg-primary1 rounded-lg p-4 text-white font-display text-[30px] font-semibold">
            Core Values and Principles
          </p>
          <div className="flex flex-row justify-start items-start bg-primary2 rounded-lg gap-4 p-4 min-h-[220px]">
            <div className="flex flex-col justify-start text-center text-4xl text-heading font-display font-medium">
              0{id}
            </div>
            <div className="flex flex-col justify-start gap-4">
              <p className="text-4xl text-heading font-display font-medium">
                {title}
              </p>
              <p className="text-base text-para font-display">{description}</p>
            </div>
          </div>
        </div>
        <div className="w-full md:w-[20%] flex flex-row items-end justify-center pb-6 gap-10 mt-6 md:mt-0">
          <img
            src={previous}
            alt="previous"
            onClick={handlePrev}
            className="cursor-pointer"
          />
          <img
            src={next}
            alt="next"
            onClick={handleNext}
            className="cursor-pointer"
          />
        </div>
      </div>
    </div>
  );
};

export default CoreValues;

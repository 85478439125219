import { useState } from "react";
import minimize from "../images/minimize.svg";
import expand from "../images/expand.png";
import webD1 from "../images/services/webd1.jpg";
import webD2 from "../images/services/webd2.jpg";
import appd1 from "../images/services/appd1.jpg";
import appd2 from "../images/services/appd2.jpeg";
import ml from "../images/services/ml.jpg";
import ai from "../images/services/ai.jpg";
import ve1 from "../images/services/ve1.jpg";
import ve2 from "../images/services/ve2.jpg";
import uiux1 from "../images/services/uiux1.jpg";
import uiux2 from "../images/services/uiux2.jpg";
import ld1 from "../images/services/ld1.jpg";
import ld2 from "../images/services/ld2.jpg";
import ts1 from "../images/services/ts1.jpg";
import ts2 from "../images/services/ts2.jpg";

const ServiceCard = ({ data, num }) => {
  const [display, setDisplay] = useState(false);

  const handleClick = (link) => {
    window.open(link, '_blank');
  };

  const imageMap = {
    "Web/E-commerce Development": [webD1, webD2],
    "App Development": [appd1, appd2],
    "Machine Learning / AI": [ml, ai],
    "Video Editing": [ve1, ve2],
    "Logo Design": [ld1, ld2],
    "UI / UX Development": [uiux1, uiux2],
    "Telegram - MiniApps & Bot Development": [ts1,ts2]
  };

  const images = imageMap[data.title] || [];

  return (
    <div className="flex flex-col items-center w-full max-w-4xl px-4 py-6 mx-auto">
      <div className="w-full flex flex-row justify-between items-center">
        <div className="flex items-center">
          <p className="text-4xl md:text-5xl font-display text-para">
            {num + 1 < 10 ? `0${num + 1}` : num + 1}
          </p>
          <p className="text-xl md:text-2xl font-display text-heading font-medium mt-2 ml-2">
            {data.title}
          </p>
        </div>
        <img
          src={display ? minimize : expand}
          alt={display ? "minimize" : "expand"}
          className="h-6 w-6 mt-2 cursor-pointer"
          onClick={() => setDisplay((prevState) => !prevState)}
        />
      </div>
      <div
        className={`transition-all duration-500 ease-in-out overflow-hidden flex flex-col items-center ${
          display ? "max-h-screen" : "max-h-0"
        }`}
      >
        <div className="flex flex-col md:flex-row gap-6 md:gap-8 justify-between mt-4 ">
          <div className="flex flex-col w-full md:w-[40%] justify-between md:ml-8 ">
            <p className="font-display text-para text-base md:text-md text-left">
              {data.description}
            </p>
            <p
              className="md:inline hidden text-white bg-primary1 font-semibold cursor-pointer w-[180px] p-2 md:p-3 text-center mt-4 md:mt-0"
              onClick={() => handleClick(data.link)}
            >
              Explore More
            </p>
          </div>
          <div className="flex flex-row gap-2 md:gap-4 w-full md:w-[60%] justify-center md:justify-end ">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={data.title}
                className="w-[180px] h-[180px] object-cover object-center"
              />
            ))}
          </div>
        </div>
        <p
          className="inline md:hidden text-white text-base md:text-xl bg-primary1 font-semibold cursor-pointer w-[180px] p-2 md:p-3 text-center mt-4"
          onClick={() => handleClick(data.link)}
        >
          Explore More
        </p>
      </div>
      <div className="w-full h-[1px] border border-para mt-8"></div>
    </div>
  );
};

export default ServiceCard;

import { Link } from "react-router-dom";
import homeBg from "../images/home_bg.png";
import approach from "../images/home_professional_service.jpg";

const Home = () => {
  return (
    <div className="flex flex-col gap-10">
      <div
        style={{ backgroundImage: `url(${homeBg})`, zIndex: 0 }}
        className="relative w-screen min-h-[89vh] bg-cover bg-no-repeat bg-center flex items-end justify-start"
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative w-[100%] md:w-[75%] lg:w-[50%] flex flex-col gap-4 mx-6 md:ml-10 lg:ml-16 mb-36 z-10">
          <p className="text-[40px] md:text-6xl font-display font-bold text-white">
            Your Vision, Our Priority
          </p>
          <p className="text-base xs:text-lg md:text-xl font-display font-medium text-white">
            We prioritize your goals and needs, delivering customized solutions
            that bring your vision to life.
          </p>
        </div>
      </div>
      <div className="w-[90vw] lg:w-[85vw] m-auto flex flex-col lg:flex-row items-center md:items-start lg:justify-between gap-6">
        <p className="w-[100%] lg:w-[40%] text-heading text-[40px] text-center md:text-left font-semibold font-display">
          Get started with us simply and quickly
        </p>
      </div>
      <div className="w-[90vw] lg:w-[85vw] m-auto flex flex-col items-center md:flex-row md:justify-between flex-wrap gap-4">
        <div className="flex flex-col items-center md:items-start justify-center gap-2 w-[75%] md:w-[48%] lg:w-[32%] min-h-[170px] shadow-md bg-primary2 rounded-lg p-4">
          <p className="text-center md:text-left text-lg font-display font-bold text-heading">
            Explore Our Services
          </p>
          <p className="text-center md:text-left text-lg font-display font-medium text-para">
            Discover the range of services we offer. Click the button to view
            details and find the perfect solution for your project.
          </p>
          <a
            href="/services"
            className="bg-primary1 text-white py-2 px-4 rounded-lg font-display font-medium mt-2 hover:bg-primary1-dark transition duration-300"
          >
            Explore Services
          </a>
        </div>

        <div className="flex flex-col items-center md:items-start gap-2 justify-center w-[75%] md:w-[48%] lg:w-[32%] min-h-[180px] shadow-md bg-primary2 rounded-lg p-4">
          <p className="text-center md:text-left text-lg font-display font-bold text-heading">
            Share Your Project Details
          </p>
          <p className="text-center md:text-left text-lg font-display font-medium text-para">
            Provide us with details about your project. Click the button below
            to fill out a form and help us understand your needs.
          </p>
          <a
            href="https://forms.gle/BMpR6QfV99zSpxUk9"
            target="_blank"
            className="bg-primary1 text-white py-2 px-4 rounded-lg font-display font-medium mt-2 hover:bg-primary1-dark transition duration-300"
            rel="noreferrer"
          >
            Fill Out Form
          </a>
        </div>

        <div className="flex flex-col items-center md:items-start gap-2 justify-center w-[75%] md:w-[48%] lg:w-[32%] min-h-[180px] shadow-md bg-primary2 rounded-lg p-4">
          <p className="text-center md:text-left text-lg font-display font-bold text-heading">
            Schedule a Consultation
          </p>
          <p className="text-center md:text-left text-lg font-display font-medium text-para">
            Let’s discuss your project in detail. Click the button to schedule a
            meeting with our team at your convenience.
          </p>
          <a
            href="https://calendly.com/clientdrivensolutions"
            target="_blank"
            className="bg-primary1 text-white py-2 px-4 rounded-lg font-display font-medium mt-2 hover:bg-primary1-dark transition duration-300"
            rel="noreferrer"
          >
            Schedule Meeting
          </a>
        </div>
      </div>

      <div className="w-[90vw] lg:w-[85vw] m-auto flex flex-col md:flex-row justify-between items-center md:items-start gap-4 md:gap-0 lg:gap-4 mt-16">
        <div className="flex justify-center items-center w-[85vw] md:w-[31vw] lg:w-[28vw]">
          <img
            src={homeBg}
            alt="review"
            className="min-h-[300px] max-w-[390] pt-[15px] object-cover"
          />
        </div>
        <div className="flex flex-col w-[85vw] md:w-[50vw] lg:w-[53vw] gap-6 md:items-start items-center mt-6 md:mt-0">
          <div className="font-display text-primary1 text-[25px] font-bold">
            Our Approach
          </div>
          <p className="text-center md:text-left font-display text-para text-lg">
            We combine client-centric focus with innovative solutions,
            leveraging our expertise in cutting-edge technologies. Through
            continuous communication, agile methodology, and collaboration, we
            deliver customized, high-quality results that meet your strategic
            objectives and adapt to your evolving needs.
          </p>
          <Link
            to="/reviews"
            className="text-white text-2xl bg-primary1 font-semibold cursor-pointer w-[250px] p-2 text-center"
          >
            Share your review
          </Link>
        </div>
      </div>
      <div className="w-[90vw] lg:w-[85vw] m-auto flex flex-col md:flex-row items-center justify-between gap-4 mt-16">
        <div className="flex flex-col items-center md:items-start gap-2 justify-center w-[90%] md:w-[48%] min-h-[210px] shadow-md bg-primary1 rounded-lg">
          <p className="text-center md:text-left text-xl font-display font-semibold text-primary2 underline px-3 pt-2">
            Our Mission
          </p>
          <p className="text-center md:text-left text-[18px] font-display text-primary2 px-3 pb-2">
            To empower businesses by delivering innovative, reliable, and
            scalable solutions in web development, app development, AI, and
            more. We are committed to achieving client success through
            integrity, collaboration, and unwavering quality in every project.
          </p>
        </div>
        <div className="flex flex-col items-center md:items-start gap-2 justify-center w-[90%] md:w-[48%] min-h-[210px] shadow-md bg-primary2 rounded-lg">
          <p className="text-center md:text-left text-xl font-display font-semibold text-primary1 underline px-3 pt-2">
            Our Vision
          </p>
          <p className="text-center md:text-left text-[18px] font-display text-para px-3 pb-2">
            To be the preferred partner for businesses worldwide, known for our
            integrity, innovation, and excellence. We aim to drive
            transformative growth for our clients, setting new standards in
            service delivery and technological advancements.
          </p>
        </div>
      </div>
      <div className="w-[90vw] lg:w-[85vw] m-auto flex flex-col-reverse md:flex-row justify-between items-center md:items-start gap-4 md:gap-0 lg:gap-4 mt-16">
        <div className="flex flex-col w-[85vw] md:w-[50vw] lg:w-[53vw] gap-6 md:items-start items-center mt-6 md:mt-0">
          <p className="text-center md:text-left font-display text-heading text-[40px] font-semibold">
            Our Professional Services
          </p>
          <p className="text-center md:text-left font-display text-para text-lg">
            We offer state-of-the-art solutions in web and app development,
            machine learning, AI, UI/UX design, video editing, and logo making.
            Our services are powered by the latest technologies and tailored to
            meet the unique needs of each client.
          </p>
          <Link
            to="/services"
            className="text-white text-2xl bg-primary1 font-semibold cursor-pointer w-[250px] p-2 text-center"
          >
            Explore our Services
          </Link>
        </div>
        <div className="flex justify-center items-center w-[85vw] md:w-[31vw] lg:w-[28vw]">
          <img
            src={approach}
            alt="review"
            className="min-h-[300px] max-w-[390] md:pt-[15px] object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default Home;

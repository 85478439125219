import aboutUs from "../images/aboutus1.png";
import { aboutUsData } from "../utils/aboutUs";
import CoreValues from "../components/CoreValues";

const About = () => {
  return (
    <div className="w-[90vw] lg:w-[85vw] m-auto flex flex-col items-center mt-4 gap-10">
      <CoreValues />
      <div className="flex flex-col md:flex-row justify-center items-center gap-4">
        <div className="flex flex-col gap-4 items-center md:items-start">
          <p className="font-display text-heading text-[40px] font-semibold text-left">
            Who we are ?
          </p>
          <p className="font-display text-para text-lg text-center md:text-left">
          We are a team of experienced professionals from premier institutions like NITs and IITs, with backgrounds in leading tech companies. We are dedicated to fulfilling our clients' needs with the utmost priority, delivering high-quality, innovative solutions.
          </p>
        </div>
        <div className="flex justify-center items-center w-[85vw] md:w-[32vw] bg-primary1">
          <img
            src={aboutUs}
            alt="review"
            className="max-h-[350px] xs:max-w-[420px] max-w-[300px]"
          />
        </div>
      </div>
      <p className="font-display text-heading text-[40px] text-left font-semibold w-full">
        Client Approach and Strategies
      </p>
      <div className="flex flex-row flex-wrap justify-center md:justify-between items-center gap-6">
        {aboutUsData.map((data, index) => (
          <div
            key={index}
            className="border-l-4 border-l-primary1 w-full is:w-[370px] flex flex-col gap2"
          >
            <p className="font-display text-heading text-xl text-semibold text-left pl-2">
              {data.title}
            </p>
            <p className="font-display text-para text-base text-left pl-2">
              {data.message}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default About;

import cardIcon from "../images/cardIcon.svg";
import fullStarIcon from "../images/full-star.png";
import halfStarIcon from "../images/half-star.svg";
import emptyStarIcon from "../images/empty-star.svg";

const ReviewCard = ({ card }) => {
  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      if (i < card.rating) {
        stars.push(
          <img key={i} src={fullStarIcon} alt="star" className="h-6 w-6" />
        );
      } else {
        stars.push(
          <img key={i} src={emptyStarIcon} alt="star" className="h-6 w-6" />
        );
      }
    }
    return stars;
  };

  return (
    <div className="flex flex-col p-6 shadow-md rounded-md w-[350px] sm:w-[400px] bg-primary2 border-2">
      <div className="flex justify-end">
        <div className="flex justify-center w-[50px] h-[50px] rounded-full bg-primary1 text-para">
          <img src={cardIcon} alt="User" className="p-2" />
        </div>
      </div>
      <div className="flex flex-col gap-4 mt-8">
        <div className="flex flex-col items-start gap-4">
          <img
            src={`${process.env.REACT_APP_BACKEND_PUBLIC}/review/${card.image}`}
            alt="profile"
            className="w-[80px] h-[80px] rounded-full object-cover"
          />
          <div className="flex flex-col font-display text-xl font-semibold text-primary1">
            {card.name}
            <span className="text-base font-display text-para">
              {card.designation}
            </span>
          </div>
          <div className="flex gap-1">{renderStars()}</div>
        </div>
        <div className="font-display text-para text-base">{card.message}</div>
      </div>
    </div>
  );

};

export default ReviewCard;

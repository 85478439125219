export const services = [
  {
    title: "Web/E-commerce Development",
    description:
      '"Because every website deserves a few extra hours of debugging – just for fun."',
    link: "https://irradiated-church-92b.notion.site/Web-Development-6040f39449e14f199d42de92a280a4a0?pvs=74",
  },
  {
    title: "App Development",
    description:
      '"Why settle for a simple app when you can have one that’s as complex as your weekend plans?"',
    link: "https://irradiated-church-92b.notion.site/App-Development-aabec44f94e6476aa3011e624b2b4157",
  },
  {
    title: "Machine Learning / AI",
    description:
      '"Turning your data into insights, because guessing was just too efficient."',
    link: "https://irradiated-church-92b.notion.site/Machine-Learning-Artificial-Intelligence-e705613f701f4f83b6d5163f63249492",
  },
  {
    title: "Video Editing",
    description: '"Creating cinematic magic, because who needs sleep anyway?"',
    link: "https://irradiated-church-92b.notion.site/Video-Editing-5b787bb5cf024dc3917e1fa9f9cccb32",
  },
  {
    title: "Logo Design",
    description:
      '"Crafting logos that make you wonder if simplicity really is overrated."',
    link: "https://irradiated-church-92b.notion.site/Logo-Design-0b847d5653c74a52a474dd121b210a30",
  },
  {
    title: "UI / UX Development",
    description:
      '"Designing interfaces so intuitive, you’ll forget how much effort we put into it."',
    link: "https://irradiated-church-92b.notion.site/UI-UX-Development-b91fe09c4b3548a2b7e9c2ffdf78a29d?pvs=74",
  },
  {
    title: "Telegram - MiniApps & Bot Development",
    description:
      '"Connecting you with your audience on Telegram, because instant messaging should be as seamless as your morning coffee."',
    link: "https://irradiated-church-92b.notion.site/Telegram-Services-7a48a3a253ba432ea32abc1231960a01",
  },
];

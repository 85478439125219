import axios from "axios"
import { handleApiError } from "../utils/errorHandler"

const BASE_URL = process.env.REACT_APP_BACKEND_URL + '/review'
const reviewAPI = axios.create({
    baseURL:BASE_URL
})

export const getReviews = async () => {
    try{
        const response = await reviewAPI.post('/get-reviews',{})
        return response.data
    }
    catch(error){
        handleApiError(error)
	throw error
    }
}

export const createReview = async (reviewData) => {
    try{
        const response = await reviewAPI.post('/create-review',reviewData)
        return response.data
    }
    catch(error){
        handleApiError(error)
	throw error
    }
}
